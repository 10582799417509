import React, { ComponentType, FunctionComponent } from 'react';
import { Theme, useTheme } from '@emotion/react';
import ReactSelect, {
  components,
  ControlProps,
  GroupBase,
  MultiValueProps,
  SingleValueProps,
  MenuListProps,
  MenuPosition,
  Theme as SelectTheme,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

// @ Libraries
import Close from '@arrive/ui-icons/svg/ic_close.svg';
import Check from '@arrive/ui-icons/svg/ic_check.svg';

// @Styles
import {
  getConfigStyles,
  SelectedValue,
  PrefixContainer,
  StyledContainer,
  OptionRow,
  MenuListContainer,
} from './DropdownStyles';
import { Asterisk } from 'styles/Asterisk';
import { StyledInputError } from 'styles/ErrorStyles';
import { palette } from 'theme/Theme';

// @Types
import type { PlaceholderProps, ValueContainerProps } from 'react-select';
import type {
  AsyncSearchableDropdownProps,
  ControlOverrideProps,
  CreatableAsyncSearchableDropdownProps,
  DisplayValueOverrideProps,
  DropdownStyleProps,
  OptionOverrideProps,
  SearchableDropdownSelectProps,
  MenuListOverrideProps,
} from './DropdownTypes.types';
import Typography from '../Typography/Typography';

const { Control, ValueContainer, Placeholder, Option, MenuList } = components;

const ControlOverride = (props: ControlOverrideProps) => {
  return (
    <Control {...props}>
      {props.selectProps.prefix && (
        <PrefixContainer size={props.selectProps.size || 'lg'}>
          {props.selectProps.prefix}
        </PrefixContainer>
      )}
      {props.children}
    </Control>
  );
};

const DisplayValueOverride = (props: DisplayValueOverrideProps) => {
  // removeProps only exists when `isMulti` is true so replicate the same behavior for single select
  const removeProps = props.removeProps || {
    onClick: props.clearValue,
    onMouseDown: (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
    },
    onTouchEnd: props.clearValue,
  };
  const { size, placeholder } = props.selectProps;
  const allProps = { ...removeProps, size, placeholder };

  return (
    <SelectedValue
      {...allProps}
      placeholder={placeholder || ''}
      size={size || 'lg'}
    >
      {props.children}
      <Close />
    </SelectedValue>
  );
};

const ValueContainerOverride = ({
  children,
  ...props
}: ValueContainerProps & PlaceholderProps) => {
  const { placeholder, isDisabled, required } =
    props.selectProps as DropdownStyleProps;

  const values = React.Children.map(children, (child) => {
    // @ts-ignore
    if (child?.key === 'placeholder') {
      return <></>;
    }
    return child;
  });

  return (
    <>
      <ValueContainer {...props}>
        <Placeholder {...props}>
          {
            <>
              {placeholder} {required && <Asterisk isDisabled={isDisabled} />}
            </>
          }
        </Placeholder>
        {values}
      </ValueContainer>
    </>
  );
};

const OptionOverride = (props: OptionOverrideProps) => {
  return (
    <Option {...props}>
      <OptionRow>
        <>
          {!props.isMulti && props.data.icon}
          {props.isSelected && props.isMulti ? (
            <div>
              <Check />
            </div>
          ) : null}
          <Typography variant="body1" margin="0">
            {props.label}
          </Typography>
          {!!props.subtext && (
            <Typography
              variant="body2"
              margin="4px 0 0 0"
              color={palette.common.smokey}
            >
              {props.subtext}
            </Typography>
          )}
        </>
      </OptionRow>
    </Option>
  );
};

const MenuListOverride = ({ children, ...props }: MenuListOverrideProps) => {
  const { dataTestId } = props.selectProps as DropdownStyleProps;
  return (
    <MenuListContainer data-testid={`${dataTestId}-menulist`}>
      <MenuList {...props}>
        {React.Children.map(children, (child) => child)}
      </MenuList>
    </MenuListContainer>
  );
};

export const overrideComponents = (
  getOptionSubtext: ((option: unknown) => string) | undefined,
) => ({
  Control: ControlOverride as
    | ComponentType<ControlProps<unknown, boolean, GroupBase<unknown>>>
    | undefined,
  Option: (props: any) => (
    <OptionOverride
      subtext={getOptionSubtext ? getOptionSubtext(props.data) : undefined}
      {...props}
    />
  ),
  MultiValue: DisplayValueOverride as
    | ComponentType<MultiValueProps<unknown, boolean, GroupBase<unknown>>>
    | undefined,
  SingleValue: DisplayValueOverride as FunctionComponent<
    SingleValueProps<unknown, boolean, GroupBase<unknown>>
  >,
  ValueContainer: ValueContainerOverride as FunctionComponent<
    ValueContainerProps<unknown, boolean, GroupBase<unknown>>
  >,
  MenuList: MenuListOverride as FunctionComponent<
    MenuListProps<unknown, boolean, GroupBase<unknown>>
  >,
  ClearIndicator: () => null,
  IndicatorSeparator: () => null,
});

export const getDropdownProps = (
  theme: Theme,
  {
    placeholder,
    required,
    isDisabled,
    isMulti,
    showArrow,
    isWarning,
    getOptionSubtext,
    ...rest
  }: SearchableDropdownSelectProps,
) => {
  return {
    backspaceRemovesValue: true,
    closeMenuOnSelect: !isMulti,
    components: showArrow
      ? overrideComponents(getOptionSubtext)
      : {
          ...overrideComponents(getOptionSubtext),
          DropdownIndicator: () => null,
        },
    hideSelectedOptions: false,
    isDisabled: isDisabled,
    menuPosition: 'fixed' as MenuPosition,
    placeholder: placeholder ? (
      <>
        {placeholder} {required && <Asterisk isDisabled={isDisabled} />}
      </>
    ) : (
      ''
    ),
    tabSelectsValue: false,
    theme: (theme: SelectTheme) => {
      return {
        ...theme,
        borderRadius: 3,
        spacing: {
          ...theme.spacing,
          controlHeight: 50,
          menuGutter: 0,
        },
      };
    },
    styles: getConfigStyles(theme, isWarning),
    ...rest,
  };
};

export const DropdownContainer = ({
  ariaLabel,
  dataTestId,
  errorText,
  children,
  blackIcons,
  isDisabled = false,
  isWarning,
}: {
  ariaLabel?: string;
  dataTestId?: string;
  errorText: string;
  children: React.ReactNode;
  blackIcons: boolean;
  isDisabled: boolean;
  isWarning?: boolean;
}) => {
  return (
    <StyledContainer
      data-testid={dataTestId}
      aria-label={ariaLabel}
      blackIcons={blackIcons}
      isDisabled={isDisabled}
    >
      {children}
      {!!errorText?.length && (
        <StyledInputError isWarning={isWarning}>{errorText}</StyledInputError>
      )}
    </StyledContainer>
  );
};

export const AsyncSearchableDropdown = ({
  errorText = '',
  showArrow = true,
  blackIcons = false,
  isDisabled = false,
  isWarning = false,
  ...rest
}: AsyncSearchableDropdownProps) => {
  const theme = useTheme();
  return (
    <DropdownContainer
      errorText={errorText}
      dataTestId={rest.dataTestId}
      ariaLabel={rest.ariaLabel}
      blackIcons={blackIcons}
      isDisabled={isDisabled}
    >
      <AsyncSelect
        {...getDropdownProps(theme, {
          showArrow,
          isDisabled,
          isWarning,
          ...rest,
        })}
        {...rest}
      />
    </DropdownContainer>
  );
};

const SearchableDropdown = ({
  errorText = '',
  showArrow = true,
  blackIcons = false,
  isDisabled = false,
  isWarning = false,
  ...rest
}: SearchableDropdownSelectProps) => {
  const theme = useTheme();
  return (
    <DropdownContainer
      errorText={errorText}
      dataTestId={rest.dataTestId}
      ariaLabel={rest.ariaLabel}
      blackIcons={blackIcons}
      isDisabled={isDisabled}
    >
      <ReactSelect
        {...getDropdownProps(theme, {
          showArrow,
          isDisabled,
          isWarning,
          ...rest,
        })}
        {...rest}
      />
    </DropdownContainer>
  );
};

export const CreatableAsyncSearchableDropdown = ({
  errorText = '',
  showArrow = true,
  blackIcons = false,
  isDisabled = false,
  isWarning = false,
  ...rest
}: CreatableAsyncSearchableDropdownProps) => {
  const theme = useTheme();

  return (
    <DropdownContainer
      errorText={errorText}
      dataTestId={rest.dataTestId}
      ariaLabel={rest.ariaLabel}
      blackIcons={blackIcons}
      isDisabled={isDisabled}
      isWarning={isWarning}
    >
      <AsyncCreatableSelect
        {...getDropdownProps(theme, {
          showArrow,
          isDisabled,
          isWarning,
          ...rest,
        })}
        {...rest}
      />
    </DropdownContainer>
  );
};

export default SearchableDropdown;
