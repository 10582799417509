import React from 'react';

// @Components
import Typography from 'commonComponents/Typography/Typography';

//@Styles
import { ButtonsContainer, ToggleButtonContainer } from './ButtonStyles';
import { palette } from 'theme/Theme';

// @Types
import { ToggleButtonProps } from './ButtonTypes.types';

const ToggleButton = ({
  leftButtonOptions,
  onClick,
  rightButtonOptions,
  selectedValue = '',
}: ToggleButtonProps) => {
  return (
    <ButtonsContainer data-testid="toggle-container">
      <ToggleButtonContainer
        data-testid={leftButtonOptions.value}
        disabled={leftButtonOptions.isDisabled || false}
        isSelected={selectedValue === leftButtonOptions.value}
        left
        onClick={onClick}
        value={leftButtonOptions.value}
      >
        {leftButtonOptions?.svg}
        <Typography
          color={
            leftButtonOptions.isDisabled
              ? palette.common.chrome
              : palette.common.axle
          }
        >
          {leftButtonOptions.label}
        </Typography>
      </ToggleButtonContainer>
      <ToggleButtonContainer
        data-testid={rightButtonOptions.value}
        disabled={rightButtonOptions.isDisabled || false}
        isSelected={selectedValue === rightButtonOptions.value}
        onClick={onClick}
        value={rightButtonOptions.value}
      >
        {rightButtonOptions?.svg}
        <Typography
          color={
            rightButtonOptions.isDisabled
              ? palette.common.chrome
              : palette.common.axle
          }
        >
          {rightButtonOptions.label}
        </Typography>
      </ToggleButtonContainer>
    </ButtonsContainer>
  );
};

export default ToggleButton;
